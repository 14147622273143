<template functionnal>
  <div class="template p-8">
    <div class="template__content">
      <div class="row">
        <div class="col-12 px-4">
          <h1>
            <span class="marker-effect">Inscriptions</span>
          </h1>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-6 px-4">
          <h6>
            Inscription de juin à septembre
          </h6>

          <div>
            <a
              href="https://www.rentree-partagee13.fr/#:~:text=Les%20tests%20de%20la%20Rentr%C3%A9e,au%20vendredi%2017%20septembre%202021."
              target="_blank"
            >
              A l’issu des évaluations de la Rentrée Partagée du 13ème en septembre
            </a>
            <br /><br />

            <strong
              >Qu’est-ce que la Rentrée partagée du 13e arrondissement et à qui
              s’adresse-t-elle?</strong
            >
            <br />
            La Rentrée partagée est une période d’évaluation des niveaux de français et
            d’orientation vers une formation, destinée à des habitants du 13e arrondissement (ou des
            personnes qui y sont hébergées, ou qui y travaillent, ou qui y sont accompagnées par un
            conseiller social ou professionnel) de plus de 18 ans qui souhaitent s’inscrire dans un
            cours de français d’octobre à juin. <br />
            <br />
            La personne inscrite doit être volontaire pour passer le test, ainsi que pour entrer en
            formation toute l’année dans des cours qui sont majoritairement payants.<br />
            Selon les structures, le tarif varie de 0 à 130€/an (payable en plusieurs fois). <br />
          </div>
        </div>

        <div class="col-6 px-4">
          <h6>
            En cours d’année
          </h6>

          <div>
            <router-link to="/contact">Nous contacter</router-link> <br /><br />
            <em>ou</em><br /><br />
            prendre un rendez-vous pour une évaluation de votre niveau de français et être orienté
            vers une structure sur
            <a href="https://www.reseau-eiffel.fr" target="_blank">https://www.reseau-eiffel.fr</a>
            (si besoin, nous pouvons vous aider à prendre rendez-vous sur Réseau Eiffel)
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
